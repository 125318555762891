<template>
    <div v-if="isMobile()" class="error--text text-h6 text-center">
        {{ $t('messages.function_work_on_desktop_only') }}
    </div>
    <div v-else>
        <div class="mb-3">
            <v-btn small
                   v-for="(tab, index) in tabs" :key="`tab_${tab.id}`"
                   :color="index === activeTab ? 'primary' : 'default'"
                   @click="changeTab(index)"
                   class="mr-2"
            >
                {{ tab.name || `Khách ${index + 1}` }}
                <v-icon class="icon-tab-close" @click="closeTab(index)" v-if="index > 0">mdi-close</v-icon>
            </v-btn>

            <v-btn small outlined color="primary" class="px-0" @click="addTab" v-if="tabs.length < 3">
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </div>
        <div>
            <OrderCreateItem v-for="(tab, index) in tabs"
                             :key="`oci_${tab.id}`"
                             :item-index="index"
                             v-show="index === activeTab"
                             @nameUpdated="itemNameUpdated"
                             @itemReset="itemReset"
            />
        </div>
    </div>
</template>

<script>
export default {
    name: "OrderCreate",
    components: {
        OrderCreateItem: () => import('@/components/pos/OrderCreateItem')
    },
    data: () => ({
        tabs: [],
        activeTab: 0
    }),
    mounted() {
        this.addTab()
    },
    methods: {
        isMobile() {
            return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
        },
        addTab() {
            this.tabs.push({
                name: null,
                id: this.generateRandomString(6)
            })
            this.changeTab(this.tabs.length - 1)
        },
        changeTab(index) {
            this.activeTab = index
        },
        closeTab(index) {
            this.tabs = [...this.tabs].filter((t, i) => i !== index)
            if (this.tabs.length === 0) {
                this.addTab()
            }
            this.changeTab(0)
        },
        itemNameUpdated(opt) {
            const tabs = [...this.tabs]
            tabs[opt.index].name = opt.name
            this.tabs = [...tabs]
        },
        itemReset(opt) {
            this.closeTab(opt.index)
        }
    }


}
</script>

<style scoped lang="scss">
.icon-tab-close {
  border-radius: 10px;
  font-size: 10px;
  width: 18px;
  height: 18px;
  letter-spacing: 0;
  pointer-events: auto;
  position: absolute;
  text-align: center;
  text-indent: 0;
  top: auto;
  transition: .3s cubic-bezier(.25,.8,.5,1);
  white-space: nowrap;
  inset: auto auto calc(100% - 4px) calc(100% - 0px);
  display: inline-block;
  z-index: 2;
  line-height: 1;
  color: #fff !important;
  background-color: #ff5252 !important;
  border: 2px solid #fff !important;
}
</style>